import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill CSS
import './css/JobManagementPage.css'; // Adjust your CSS path
import {
  fetchCourses,
  fetchApplicantsForCourse,
  postCourse,
  deleteCourse,
  updateCourse,
} from '../../services/api';
import CourseForm from './components/CourseForm';
import CoursesList from './components/CoursesList';
import ApplicantsList from './components/ApplicantsList';
// import TechStackPopup from './components/TechStackPopup';

const CoursesPage = ({ userRole, access }) => {
  const [courses, setCourses] = useState([]);
  const [applicants, setApplicants] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null);
  // const [showTechStackPopup, setShowTechStackPopup] = useState(false);
  const [formValues, setFormValues] = useState({
    CourseName: '',
    duration: '',
    location: '',
    fees: 0,
    startDate: '',
    description: '',
    // techStacks: [],
  });
  // const [techStacks, setTechStacks] = useState([{ tech: '', topics: '' }]);

  const edit = userRole === 'admin' || userRole === 'editor' || access?.Courses?.edit;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchCourses();
        setCourses(data);
      } catch (error) {
        console.error('Error fetching courses:', error);
      }
    };
    fetchData();
  }, []);

  const handlePostCourse = async (e) => {
    e.preventDefault();
    try {
      if (selectedCourse) {
        await updateCourse(selectedCourse._id, { ...formValues});
        Swal.fire('Success', 'Course updated successfully!', 'success');
      } else {
        await postCourse({ ...formValues});
        Swal.fire('Success', 'Course posted successfully!', 'success');
      }
      const data = await fetchCourses();
      setCourses(data);
      resetForm(); // Reset the form after submission
    } catch (error) {
      console.error('Error posting/updating course:', error);
    }
  };

  const handleDeleteCourse = async (courseId) => {
    try {
      await deleteCourse(courseId);
      Swal.fire('Success', 'Course deleted successfully!', 'success');
      const data = await fetchCourses();
      setCourses(data);
    } catch (error) {
      console.error('Error deleting course:', error);
    }
  };

  const handleUpdateCourse = (course) => {
    setFormValues({
      CourseName: course.CourseName,
      duration: course.duration,
      location: course.location,
      fees: course.fees,
      startDate: course.startDate,
      description: course.description,
      // techStacks: course.techStacks,
    });
    setSelectedCourse(course); 
  };

  const handleCourseClick = async (course) => {
    setSelectedCourse(course);
    try {
      const data = await fetchApplicantsForCourse(course._id);
      setApplicants(data);
    } catch (error) {
      console.error('Error fetching applicants:', error);
    }
  };

  const resetForm = () => {
    setFormValues({
      CourseName: '',
      duration: '',
      location: '',
      fees: 0,
      startDate: '',
      description: '',
      // techStacks: [],
    });
    setSelectedCourse(null); // Reset selected course
  };

  return (
    <div className="job-management-page">
      <h3>Courses</h3>
      {edit && (
        <form onSubmit={handlePostCourse} className="post-job-form">
          <h2>{selectedCourse ? 'Update Course' : 'Post a New Course'}</h2>
          <div>
            <label>Course Name:</label>
            <input
              type="text"
              value={formValues.CourseName}
              onChange={(e) => setFormValues({ ...formValues, CourseName: e.target.value })}
              required
            />
          </div>
          <div>
            <label>Duration:</label>
            <input
              type="text"
              value={formValues.duration}
              onChange={(e) => setFormValues({ ...formValues, duration: e.target.value })}
              required
            />
          </div>
          <div>
            <label>Location:</label>
            <input
              type="text"
              value={formValues.location}
              onChange={(e) => setFormValues({ ...formValues, location: e.target.value })}
              required
            />
          </div>
          <div>
            <label>Fees:</label>
            <input
              type="number"
              value={formValues.fees}
              onChange={(e) => setFormValues({ ...formValues, fees: e.target.value })}
              required
            />
          </div>
          <div>
            <label>Start Date:</label>
            <input
              type="date"
              value={formValues.startDate}
              onChange={(e) => setFormValues({ ...formValues, startDate: e.target.value })}
              required
            />
          </div>
          <div >
            <label>Description</label>
            <ReactQuill
              value={formValues.description}
              onChange={(value) => setFormValues({ ...formValues, description: value })}
              placeholder="Write the course description here..."
              modules={CoursesPage.modules}
              formats={CoursesPage.formats}
              required
            />
          </div>
          {/* <button type="button" onClick={() => setShowTechStackPopup(true)}>Add Tech Stacks</button> */}
          <button type="submit">{selectedCourse ? 'Update Course' : 'Post Course'}</button>
          <button type="button" onClick={resetForm}>Reset Form</button>
        </form>
      )}
      <div className="jobs-applicants-container">
        <CoursesList
          edit={edit}
          courses={courses}
          handleCourseClick={handleCourseClick}
          handleUpdateCourse={handleUpdateCourse}
          handleDeleteCourse={handleDeleteCourse}
        />
        <ApplicantsList applicants={applicants} selectedCourse={selectedCourse} resume={false} />
      </div>
      {/* {showTechStackPopup && (
        <TechStackPopup
          techStacks={techStacks}
          setTechStacks={setTechStacks}
          setShowTechStackPopup={setShowTechStackPopup}
        />
      )} */}
    </div>
  );
};

// Quill editor configurations
CoursesPage.modules = {
  toolbar: [
    [{ header: '1' }, { header: '2' }, { font: [] }],
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    ['link'],
    ['clean'],
  ],
};

CoursesPage.formats = [
  'header', 'font', 'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 
];

export default CoursesPage;
