import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { fetchBlogid } from "../services/api";
import "./css/BlogDetailPage.css";
import Loader from "../components/Loader";

const BlogDetailPage = () => {
  const params = useParams();
  const location = useLocation();
  console.log(location, "location");

  const { id } = location.state || {};

  console.log(id, params, "id of the blog");

  const [selectedBlog, setSelectedBlog] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSelectedBlog = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await fetchBlogid(id);
        setSelectedBlog(response);
      } catch (error) {
        console.error("Error fetching blog details:", error);
        setError("Failed to load blog details. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchSelectedBlog();
  }, [id]);

  return (
    <div className="blog-detail-page main">
      {loading ? (
        <Loader />
      ) : error ? (
        <p className="error-message">{error}</p>
      ) : selectedBlog ? (
        <div className="blog-detail">
          <div className="blog-info">
            <h1>{selectedBlog.title}</h1>
            {selectedBlog?.image && (
              <img src={selectedBlog?.image} alt={selectedBlog.title} />
            )}
            <div
              className="blog-description"
              dangerouslySetInnerHTML={{ __html: selectedBlog.fulldescription }}
            />
          </div>
        </div>
      ) : (
        <p className="info">No blog found.</p>
      )}
    </div>
  );
};

export default BlogDetailPage;
