import React from 'react';
import './css/BlogsSection.css'; // Ensure this file contains the styling for the blog section

const BlogsSection = () => {
  return (
    <section className="blog-highlight-section">
      <div className="blog-highlight-container">
        {/* Text Content on the Left with Fade Right Animation */}
        <div data-aos="fade-right" className="blog-highlight-content">
          <h1 className='header'>Explore VijPrat Tech Blogs</h1>
          <p>
            Our blog is a knowledge hub for anyone looking to stay ahead in the rapidly evolving world of technology. At VijPrat Tech, we cover a wide range of topics aimed at empowering both tech enthusiasts and business leaders. From insights on cutting-edge technologies to practical tips for enhancing your business, our blog has something for everyone.
          </p>
          <p>
            Dive into articles focused on Cloud Computing, DevOps strategies, Software Development, and Digital Marketing. Whether you want to learn how to scale your infrastructure, streamline your workflows, or explore the latest in automation, our expert team provides comprehensive guides and actionable insights.
          </p>
          {/* <a href="/blog" className="read-more-btn">Explore Our Blog</a> */}
        </div>
        
        {/* Image on the Right with Fade Left Animation */}
        <div data-aos="fade-left" className="blog-highlight-image">
          <img 
            src="../assets/blogs1.avif" // Replace with your image URL
            alt="Blog Overview"
          />
        </div>
      </div>

     
    </section>
  );
}

export default BlogsSection;