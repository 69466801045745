import React from "react";
import "./css/ContactusPage.css"; // Import CSS for styles
import {
  FaPhoneAlt,
  FaTools,
  FaFacebook,
  FaLinkedin,
  FaInstagram,
} from "react-icons/fa"; // Icons from react-icons
import { FaXTwitter } from "react-icons/fa6";
import ContactForm from "../components/ContactForm";
import MapBox from "../components/MapBox"; // Import the new MapBox component

const ContactUsPage = () => {
  return (
    <section className="contact-us-page main">
      {/* Top Section with Background Image and Text */}
      <div className="contact-bg-section">
        <div data-aos="fade-right" className="contact-text">
          <p>To leverage the best of Cloud & DevOps, contact VijPrat today.</p>
        </div>
      </div>

      {/* Two-column Section */}
      <div className="contact-section">
        <div data-aos="fade-right" className="contact-info">
          {/* Marketing & Sales Box */}
          <div data-aos="fade-up" className="contact-box">
            <div className="icon-wrapper">
              <div className="icon-circle">
                <FaPhoneAlt className="icon" />
              </div>
            </div>
            <div className="text-content">
              <h3>Marketing & Sales</h3>
              <p>
                Get in touch with our sales & marketing team for tailoring a
                perfect plan for your use case.
              </p>
              <a href="mailto:connect@vijprat.com">connect@vijprat.com</a>
            </div>
          </div>

          {/* Technical Support Box */}
          <div data-aos="fade-up" className="contact-box reverse">
            <div className="icon-wrapper">
              <div className="icon-circle">
                <FaTools className="icon" />
              </div>
            </div>
            <div className="text-content">
              <h3>Technical Support</h3>
              <p>
                Our support team is here for you. Let us know your query & we
                will ensure you a quick solution.
              </p>
              <a href="mailto:vijprattech@gmail.com">vijprattech@gmail.com</a>
            </div>
          </div>

          {/* Follow Us and Locations */}
          <div data-aos="fade-up" className="contact-box social-box">
            <div>
              <h3>Follow Us</h3>
              <div className="social-icons">
                <a
                  href="https://www.facebook.com/profile.php?id=61566515007689"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaFacebook />
                </a>
                {/* <a
                  href="https://www.twitter.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaXTwitter />
                </a> */}
                <a
                  href="https://www.linkedin.com/company/vijprat-tech-private-limited/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLinkedin />
                </a>
                <a
                  href=" https://www.instagram.com/vijprattechprivate"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaInstagram />
                </a>
              </div>
            </div>
            <div>
              <h3>Our Locations</h3>
              <p>
                Office 1: Sangam Vihar, Hamdard Nagar, New Delhi, India, 110062.
              </p>
              <p>Office 2: 200 Kirwin Dr Front Worth, TX 76131 United States</p>
            </div>
          </div>
        </div>

        {/* Contact Form */}
        <ContactForm />
      </div>

      {/* New Section: Locations */}
      <div className="social-location-section">
        {/* Use MapBox component for locations */}
        <MapBox
          title="Sangam Vihar Location"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14027.807036022712!2d77.25175!3d28.50181!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce1de7ecac227%3A0xfbed0e9cbfdf86e5!2sSangam%20Vihar%2C%20Hamdard%20Nagar%2C%20South%20Delhi%2C%20New%20Delhi%2C%20Delhi%20110062!5e0!3m2!1sen!2sin!4v1631519974720!5m2!1sen!2sin"
        />
        <MapBox
          title="Fort Worth Location"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3358.808008402888!2d-97.35992!3d32.91077!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864dd58dd55e5f3b%3A0xc1c4c84c84a33e1b!2s200%20Kirwin%20Dr%2C%20Fort%20Worth%2C%20TX%2076131%2C%20USA!5e0!3m2!1sen!2sin!4v1631519974720!5m2!1sen!2sin"
        />
      </div>
    </section>
  );
};

export default ContactUsPage;
