import React from 'react';

const ApplicantsList = ({ applicants, selectedCourse, resume }) => {
  return (
    <div className="applicants-section">
      <h2>Applicants for: {selectedCourse ? selectedCourse.title || selectedCourse.CourseName : ''}</h2>
      
      {applicants.length === 0 ? (
        <p>No applicants for this Post.</p>
      ) : (
        <div className="applicants-table-container">
          <table className="courses-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Phone</th>
                {resume && <th>Resume</th>} {/* Conditionally render the Resume header */}
              </tr>
            </thead>
            <tbody>
              {applicants.map((applicant) => (
                <tr key={applicant._id}>
                  <td data-label="Name">{applicant.applicantName}</td>
                  <td data-label="Email">{applicant.applicantEmail}</td>
                  <td data-label="Phone">{applicant.applicantPhone}</td>
                  {resume && ( // Conditionally render the Resume cell
                    <td data-label="Resume">
                      <a href={applicant.resumeUrl} target="_blank" rel="noopener noreferrer">
                        View Resume
                      </a>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default ApplicantsList;
