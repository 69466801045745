import React from 'react';
import './css/AboutUs.css'; 

const AboutSection = () => {
  return (
    <section className="about-us-section">
        
      <div className="about-us-container">
    
        <div data-aos="fade-right" className="about-us-content">
        <h1 className='header'>About Vijprat</h1>
          <p>
          At VijPrat Tech, we provide comprehensive IT solutions that empower businesses to thrive in today’s digital landscape. From cloud and DevOps services to software development and testing, we streamline your operations for enhanced efficiency and scalability.

          </p>
          <p>
          Our team of experienced professionals excels in automating workflows, optimizing cloud infrastructure, and ensuring seamless product delivery. In addition to technical expertise, we offer product management and digital marketing services to help your business succeed at every stage from development to market launch.
    
          </p>
          
        </div>
        <div data-aos="fade-left"className="about-us-image">
          <img 
            src="../assets/about.jpg" 
            alt="Cloud and Networking Illustration"
          />
        </div>
      </div>
    </section>
  );
}

export default AboutSection;
