import React from "react";
import "./css/AboutPage.css";
// import { FaLinkedin } from 'react-icons/fa';
import {
  BsFill1CircleFill,
  BsFill2CircleFill,
  BsFill3CircleFill,
  BsFill4CircleFill,
} from "react-icons/bs";

import { useNavigate } from "react-router-dom";

// const teamMembers = [
//   {
//     name: 'John Doe',
//     role: 'CEO',
//     image: 'https://via.placeholder.com/200x200',
//     linkedIn: 'https://linkedin.com'
//   },
//   {
//     name: 'Jane Doe',
//     role: 'CTO',
//     image: 'https://via.placeholder.com/200x200',
//     linkedIn: 'https://linkedin.com'
//   },
//   {
//     name: 'Jane Doe',
//     role: 'CTO',
//     image: 'https://via.placeholder.com/200x200',
//     linkedIn: 'https://linkedin.com'
//   },
//   {
//     name: 'Jane Doe',
//     role: 'CTO',
//     image: 'https://via.placeholder.com/200x200',
//     linkedIn: 'https://linkedin.com'
//   },

//   // Add more team members as needed
// ];
const whatwedo = [
  {
    id: 1,
    icon: <BsFill1CircleFill size={32} />,
    title: "Trusted by Big Enterprises",
    description:
      "Big enterprises have complex focused problems to solve. Via our deep technical capabilities, constant availability of the right talent.",
  },
  {
    id: 2,
    icon: <BsFill2CircleFill size={32} />,
    title: "Global Talent Pool",
    description:
      "We leverage a global talent pool to ensure that the right resources are always available at the right time.",
  },
  {
    id: 3,
    icon: <BsFill3CircleFill size={32} />,
    title: "Comprehensive Solutions",
    description:
      "Our comprehensive solutions ensure that enterprises are ready for the future with cutting-edge technology.",
  },
  {
    id: 4,
    icon: <BsFill4CircleFill size={32} />,
    title: "Innovation and Automation",
    description:
      "We focus on innovation and automation to help companies stay ahead of the curve in a rapidly changing market.",
  },
];
const AboutPage = () => {
  const navigate = useNavigate();

  return (
    <div className="main">
      {/* Banner Section */}
      <div className="banner">
        <div className="banner-text">
          <p>We understand the world of Cloud & DevSecOps.</p>
        </div>
      </div>

      {/* Story Section */}
      <section className="story-section">
        <div className="container">
          <div className="row">
            <div data-aos="fade-right" className="col-md-6">
              <img
                src="./assets/story.jpg"
                alt="Our Story"
                className="about-story-image"
              />
              <div className="client-box text-center">
                <h3>6</h3>
                <p>Years Experience</p>
              </div>
            </div>
            <div data-aos="fade-left" className="col-md-6">
              {/* <p className="story-subheading">A Journey of progress</p> */}
              <h1 className="header">OUR STORY</h1>
              <p className="story-text">
                At VijPrat Tech, we provide comprehensive IT solutions that
                empower businesses to thrive in today's digital landscape. From
                cloud and DevOps services to software development and testing,
                we streamline your operations for enhanced efficiency and
                scalability.
              </p>
              <p className="story-text">
                Our team of experienced professionals excels in automating
                workflows, optimizing cloud infrastructure, and ensuring
                seamless product delivery. In addition to technical expertise,
                we offer product management and digital marketing services to
                help your business succeed at every stage from development to
                market launch.
              </p>
              <p className="text-right">
                “Partner with VijPrat Tech for end-to-end solutions that drive
                innovation and growth.”
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Team Section */}
      {/* <section className="team-section">
      <div data-aos="fade-right" className="container">
        <h1 className="team-heading heading">Our Executive Team</h1>
        <p className="team-subheading">The pillar stones of Vijprat's highly effective teams & their progress.</p>
        <div className="row">
          {teamMembers.map((member, index) => (
            <div data-aos="zoom-in" className="col-md-3 mb-4" key={index}>
              <div className="team-member-card">
                <div className="team-member-image">
                  <img src={member.image} alt={`Team Member ${index + 1}`} />
                  <div className="team-member-overlay">
                    <a className='linkedin' href={member.linkedIn} target="_blank" rel="noopener noreferrer">
                    <FaLinkedin size={36} color="#0e76a8" /> 
                    </a>
                   
                  </div>
                </div>
                <h2>{member.name}</h2>
                <p>{member.role}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section> */}

      <section className="what-we-do-section">
        <div className="container">
          <div className="row">
            <div data-aos="fade-right" className="col-md-4">
              <div className="what-we-do-text">
                <h1 className="header">Why Vijprat</h1>
                <h2 className="sub-header">What we do</h2>
                <p>
                  Clients choose us because of our ability to improve business
                  agility and reduce costs. We are differentiated by our:
                </p>
              </div>
            </div>
            <div data-aos="fade-left" className="col-md-8">
              <div className="row">
                {whatwedo.map((work) => (
                  <div data-aos="tilt" className="col-md-6" key={work.id}>
                    <div className="what-we-do-card">
                      {work.icon}
                      <h2>{work.title}</h2>
                      <p>{work.description}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="synergy-section">
        <div data-aos="zoom-out" className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="synergy-text">
                <h2 className="sub-header">LET’S CREATE SYNERGY</h2>
                <h1 className="header">
                  The industry choice for creating Cloud & DevSecOps
                  possibilities
                </h1>
                <button onClick={() => navigate("/contact-us")}>
                  Contact with us
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutPage;
