import React from 'react';
import './css/ServiceDetail.css'; 
import { Typewriter } from 'react-simple-typewriter';

const DetailsSection = ({name, images, explanation }) => {
  const typewriterWords = ['How We Do It.', 'How we work.', 'What we Offer.', 'Why we ?'];


  return (
    <section className="detailsSection">
      <h1 className='header' >{name}</h1>
      <h2 className='sub-header'>{' '} <span>
            <Typewriter 
              words={typewriterWords}
              loop={0}
              cursor
              cursorStyle="|"
              typeSpeed={100}
              deleteSpeed={50}
              delaySpeed={1000}
              
            /></span></h2>
      <div className="detailsContainer">
        <div data-aos="fade-right" className="singleImage">
          <img src={images[0]} alt="Service poster" />
        </div>
        <div data-aos="fade-left" className="explanationBox">
          <ul>
            {explanation.map((point, index) => (
              <li key={index}>{point}</li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default DetailsSection;
