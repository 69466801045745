import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Typewriter } from "react-simple-typewriter";
import "./css/HomeTopSection.css";

const HomeTopSection = () => {
  const typewriterWords = [
    "DevOps Services",
    "Web Development",
    "Cloud Services ",
    "Salesforce",
  ];


  // 15 15
  const swiperImages = [
    "../assets/HometopDevops.jpeg",
    "../assets/hometop.jpeg",
    "../assets/hometop1.webp",
    "../assets/sf1.jpg",
  ];

  return (
    <section className="HomeTopSection">
      <Swiper
        modules={[Autoplay, Navigation, Pagination]}
        navigation={false}
        pagination={false}
        autoplay={{ delay: 6400 }}
        className="mySwiper"
        loop={true}
      >
        {swiperImages.map((image, index) => (
          <SwiperSlide key={index}>
            <img src={image} alt={`Slide ${index}`} />
          </SwiperSlide>
        ))}
      </Swiper>

      {/* Overlay Content */}
      <div className="overlay-content">
        <div className="animatedImageContainer">
          <img src="../assets/logo.png" alt="3D" className="animatedImage" />
          <div className="animatedShadow"></div>
        </div>
        <h2>
          We work on{" "}
          <span className="typewriterText">
            <Typewriter
              words={typewriterWords}
              loop
              cursorStyle="|"
              typeSpeed={200}
              deleteSpeed={200}
              delaySpeed={100}
            />
          </span>
        </h2>
      </div>
    </section>
  );
};

export default HomeTopSection;
