import React from "react";

import "./css/ServicesPage.css";
import FooterCertificates from "../../components/FooterCertificates";
import ImageSlider from "../../components/ImageSlider";

import ServicesSwiper from "../../components/ServicesSwiper";
import Promo from "../../components/Promo";
import DetailsSection from "../../components/ServiceDetails";

const AzureServicePage = () => {
  const service = {
    name: "Azure",
    description:
      "Microsoft Azure provides a comprehensive set of cloud services...",
    images: [
      // '../assets/azure/azure.png',
      "../assets/azure/azure-1.png",
      "../assets/azure/azure2.jpg",

      "../assets/azure/azure4.jpg",
    ],
    explanation: [
      //
      "Design and deploy secure, scalable cloud infrastructure on Azure.",
      "Expertise in managing Azure services to optimize performance and reduce costs.",

      "Leverage Azure's pay-as-you-go model for flexible and cost-effective solutions.",

      "High availability and disaster recovery solutions tailored to your needs.",

      "Utilize Azure's advanced security and compliance features for enterprise-grade protection.",

      "Seamless integration of on-premise infrastructure with Azure hybrid cloud services.",

      "Automated CI/CD pipelines using Azure DevOps for faster software delivery.",

      "24/7 monitoring and support for Azure environments, ensuring maximum uptime.",
    ],
    quotes: ["", "", ""],
    certificatesData: [
      {
        src: "../assets/certificates/azure-devops-engineer.png",
        alt: "Azure DevOps Engineer",
      },
      {
        src: "../assets/certificates/azure-fundamentals.png",
        alt: "Azure Fundamentals",
      },
      {
        src: "../assets/certificates/azure-solutions-architect.png",
        alt: "Azure Solutions Architect",
      },
    ],
    swipperimages: [
      {
        src: "../assets/azureSolutions/avm.png",
        caption: "Azure Vertual Machine",
      },
      {
        src: "../assets/azureSolutions/azurebackup.jpeg",
        caption: "Azure Backup",
      },

      {
        src: "../assets/azureSolutions/azurebastion.png",
        caption: "Azure Bastion",
      },
      {
        src: "../assets/azureSolutions/azuredevops.png",
        caption: "Azure Devops",
      },
      {
        src: "../assets/azureSolutions/azure-kubernetes-service.jpg",
        caption: "Azure Kubernetes",
      },
      { src: "../assets/azureSolutions/azuresql.jpg", caption: "Azure SQL" },
      {
        src: "../assets/azureSolutions/blobstorage.png",
        caption: "Azure Blob Storage",
      },
    ],
  };

  return (
    <section className="main">
      {/* Top Section with Background Image and Swiper */}
      <div className="serviceHeader">
        <ImageSlider images={service.images} quotes={service.quotes} />
      </div>

      {/* Details Section */}
      <DetailsSection
        name={service.name}
        images={service.images}
        explanation={service.explanation}
      />

      {/* New Section with Background Image and Button */}

      <ServicesSwiper swipperimages={service.swipperimages} />

      <Promo />

      {service.certificatesData.length > 0 && (
        <FooterCertificates certificates={service.certificatesData} />
      )}
    </section>
  );
};

export default AzureServicePage;
