import React from "react";

import "./css/HomePage.css";
import Stats from "../components/Stats";
import { Link } from "react-router-dom";

import { useNavigate } from "react-router-dom";
import TestimonialsSection from "../components/TestimonialsSection";
import ContactForm from "../components/ContactForm";
import HomeTopSection from "../components/HomeTopSection";
import {
  FaAws,
  FaDev,
  FaArrowRight,
  FaSalesforce,
  FaGlobe,
  FaChalkboardTeacher,
  FaCheckCircle,
  FaNetworkWired,
  FaCloud,
  FaTasks,
  FaBullhorn,
} from "react-icons/fa";
import { SiGooglecloud, SiMicrosoftazure } from "react-icons/si";
import AboutSection from "../components/AboutSection";
import ChatUI from "../components/ChatUiSection";

const services = [
  {
    title: "Cloud Solutions",
    description:
      "Leverage the full power of cloud services, from infrastructure to security, tailored to your needs.",
    icon: <FaCloud />,
    icons: [
      {
        icon: <FaAws />,
        link: "/services/aws",
        text: "AWS",
      },
      {
        icon: <SiMicrosoftazure />,
        link: "/services/azure",
        text: "Microsoft azure",
      },
      {
        icon: <SiGooglecloud />,
        link: "/services/googleCloud",
        text: "Google Cloud",
      },
    ],
  },
  {
    title: "DevOps",
    description:
      "Empower your software delivery pipeline with our expert DevOps solutions, enabling automation, CI/CD, and more.",
    icon: <FaDev />,
    link: "/services/devops",
  },
  {
    title: "Salesforce CRM",
    description:
      "Optimize your customer relationships and streamline operations with Salesforce CRM integrations.",
    icon: <FaSalesforce />,
    link: "/services/salesforce",
  },
  {
    title: "Web & App Development",
    description:
      "Create robust, responsive websites tailored to your business needs with our expert web & App development services.",
    icon: <FaGlobe />,
    link: "/services/webAppDev",
  },
  {
    title: "Automation & Manual Testing",
    description:
      "Ensure quality software with our comprehensive manual and automation testing services.",
    icon: <FaCheckCircle />,
    link: "/services/testing",
  },
  {
    title: "IT Infra & Networking",
    description:
      "Optimize your IT infrastructure and network with scalable solutions to meet your business needs.",
    icon: <FaNetworkWired />,
    link: "/services/networking",
  },
  {
    title: "Product Management",
    description:
      "Unlock the potential of Product Management Azure with our secure, scalable cloud computing solutions.",
    icon: <FaTasks />,
    link: "/services/productManagement",
  },
  {
    title: "Trainings & Courses",
    description:
      "Advance your career with our expert-led trainings and certifications in cloud, DevOps, and more.",
    icon: <FaChalkboardTeacher />,
    link: "/services/courses",
  },

  {
    title: "Digital Marketing",
    description:
      "Maximize your business potential with Our Digital Marketing Experts",
    icon: <FaBullhorn />,
    link: "/services/DigitalMarketing",
  },
];

const HomePage = () => {
  const navigate = useNavigate();

  return (
    <div className="main">
      {/* Swiper Section */}
      <HomeTopSection />

      {/* chat section */}
      {/* <ChatUI /> */}

      {/* About section */}
      <AboutSection />

      {/* Stats section */}
      <Stats />

      {/* Service Section */}

      <section className="servicesSection">
        <h1 className="header">Our Services</h1>

        <div className="servicesContainer">
          {services.map((service, index) => (
            <div key={index} data-aos="tilt" className="serviceCard">
              {/* Service Icon */}
              <h3>{service.icon}</h3>

              {/* Service Title */}
              <h3>{service.title}</h3>

              {/* Service Description */}
              <p>{service.description}</p>

              {/* Conditional rendering for Cloud Solutions */}
              {service.icons ? (
                <div className="cloudServiceIcons">
                  {service.icons.map((cloud, cloudIndex) => (
                    <div key={cloudIndex} className="cloudServiceIcon">
                      <Link to={cloud.link} className="cloudIconLink">
                        {cloud.icon} <br />
                        {cloud.text}
                      </Link>
                    </div>
                  ))}
                </div>
              ) : (
                <Link className="cloudIconLink" to={service.link}>
                  Learn more <FaArrowRight />
                </Link>
              )}
            </div>
          ))}
        </div>
      </section>

      {/* Fixed Background Section */}
      <section className="fixedBackgroundSection">
        <div className="fixedBackgroundContent">
          <h2 className="sub-header">
            A learning culture for super enthusiastic people
          </h2>
          <p>
            VijPrat’s progressive, nurturing and open environment is a fun place
            to work and grow. We’re constantly in search of talented
            professionals who can proactively work in a dynamic work environment
            and help us craft the best solutions for our clients.
          </p>
          <div className="buttons">
            <button
              className="btnPrimary"
              onClick={() => navigate("/life-at-vijprat")}
            >
              Life at Vijprat
            </button>
            <button
              className="btnSecondary"
              onClick={() => navigate("/careers")}
            >
              Work with us
            </button>
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      <TestimonialsSection />

      {/* Main Contact Section */}
      <h1 className="header">Let’s Plan Your Project</h1>
      <section className="contactSection">
        {/* Contact Info (Text side) */}
        <div data-aos="fade-right" className="contactInfo">
          <h2 className="sub-header">Let’s make your dream a reality.</h2>
          <div className="mailIcon">
            {/* Optional Icon or Image */}
            <img src="./assets/contactUs.png" alt="Contact Icon" />
          </div>
        </div>

        <ContactForm />
      </section>
    </div>
  );
};

export default HomePage;
