import React from "react";

import "./css/ServicesPage.css";
import FooterCertificates from "../../components/FooterCertificates";
import ImageSlider from "../../components/ImageSlider";

import ServicesSwiper from "../../components/ServicesSwiper";
import Promo from "../../components/Promo";
import DetailsSection from "../../components/ServiceDetails";

const GCServicePage = () => {
  const service = {
    name: "Google Cloud",
    description: "Google Cloud provides a suite of cloud computing services...",
    images: [
      "../assets/googlecloud/gc2.jpg",
      "../assets/googlecloud/gc3.png",
      "../assets/googlecloud/gc4.jpg",
    ],
    explanation: [
      // Google Cloud-

      "Build reliable, scalable cloud infrastructure with Google Cloud.",
      "Expert management of Google Cloud services for enhanced performance and efficiency.",
      "Flexible pricing with Google Cloud’s pay-as-you-go model, maximizing cost savings.",
      "High availability solutions with global load balancing and failover capabilities.",
      "Leverage Google Cloud’s AI and machine learning services for data-driven insights.",
      "Enterprise-grade security and compliance with Google Cloud’s built-in security tools.",
      "Seamless hybrid cloud integration for on-premise and cloud environments.",

      "24/7 monitoring and support for Google Cloud infrastructure to ensure smooth operations.",
    ],
    quotes: ["", "", ""],
    certificatesData: [
      {
        src: "../assets/certificates/google-cloud-cloud.png",
        alt: "Google Cloud CloudCertificate",
      },
      {
        src: "../assets/certificates/google-cloud-data.png",
        alt: "Google Cloud DataCertificate",
      },
      {
        src: "../assets/certificates/google-cloud-devops.png",
        alt: "Google Cloud DevOpsCertificate",
      },
    ],
    swipperimages: [
      {
        src: "../assets/googlecloudSolutions/cloudbuild.png",
        caption: "Google cloud Build",
      },
      {
        src: "../assets/googlecloudSolutions/cloudcdn.png",
        caption: "Google cloud cdn",
      },
      {
        src: "../assets/googlecloudSolutions/cloudfunctions.png",
        caption: "Google Cloud functions",
      },
      {
        src: "../assets/googlecloudSolutions/cloudpubsub.png",
        caption: "Google Cloud Pub Sub",
      },
      {
        src: "../assets/googlecloudSolutions/cloudspanner.jpg",
        caption: "Google Cloud Spanner",
      },
      {
        src: "../assets/googlecloudSolutions/cloudsql.jpg",
        caption: "Google Cloud SQL",
      },
      {
        src: "../assets/googlecloudSolutions/computeengine.jpg",
        caption: "Google Cloud Compute Engine",
      },
      {
        src: "../assets/googlecloudSolutions/googlekubernets.png",
        caption: "Google Kubernets",
      },
    ],
  };

  return (
    <section className="main">
      {/* Top Section with Background Image and Swiper */}
      <div className="serviceHeader">
        <ImageSlider images={service.images} quotes={service.quotes} />
      </div>

      {/* Details Section */}
      <DetailsSection
        name={service.name}
        images={service.images}
        explanation={service.explanation}
      />

      {/* New Section with Background Image and Button */}

      <ServicesSwiper swipperimages={service.swipperimages} />

      <Promo />

      {service.certificatesData.length > 0 && (
        <FooterCertificates certificates={service.certificatesData} />
      )}
    </section>
  );
};

export default GCServicePage;
