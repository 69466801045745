import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import {  Pagination, Autoplay  } from 'swiper/modules';

const TestimonialsSection = () => {
  const testimonials = [
    {
      name: 'Emily Carter',
      text: 'The guidance from Vijprat on Salesforce was invaluable!',
      position: 'Sales Manager',
      imageUrl: '../assets/testimonial/client6.jpg',
    },
    {
      name: 'Michael Johnson',
      text: 'Thanks to Vijprat, our AWS costs were significantly reduced.',
      position: 'IT Director',
      imageUrl: '../assets/testimonial/client2.jpg',
    },
    {
      name: 'Sarah Thompson',
      text: 'Vijprat\'s expertise streamlined our operations using Azure.',
      position: 'Project Manager',
      imageUrl: '../assets/testimonial/client5.jpg',
    },
    {
      name: 'Danish',
      text: 'Migration to Google Cloud was seamless with Vijprat’s help!',
      position: 'CTO, Digital Solutions',
      imageUrl: '../assets/testimonial/client4.jpg',
    },
    {
      name: 'James Anderson',
      text: 'Vijprat significantly accelerated our development cycle through DevOps!',
      position: 'CTO',
      imageUrl: '../assets/testimonial/client1.jpg',
    },
    // {
    //   name: 'Laura Williams',
    //   text: 'Vijprat provides excellent, quick response for application support!',
    //   position: 'Lead Developer',
    //   imageUrl: '../assets/testimonial/client5.jpg',
    // },
    // {
    //   name: 'David Brown',
    //   text: 'The website redesign delivered by Vijprat was impressive!',
    //   position: 'Operations Head',
    //   imageUrl: '../assets/testimonial/client2.jpg',
    // },
    // {
    //   name: 'Jennifer Lee',
    //   text: 'Vijprat\'s AWS integration greatly enhanced our scalability.',
    //   position: 'Marketing Manager',
    //   imageUrl: '../assets/testimonial/client5.jpg',
    // }
  
  ];

  // const videos = [
  //   {
  //     videoUrl: 'https://www.youtube.com/embed/embed_id',
  //   },
  //   {
  //     videoUrl: 'https://www.youtube.com/embed/embed_id',
  //   },
  //   {
  //     videoUrl: 'https://www.youtube.com/embed/embed_id',
  //   },
  // ];

  return (
    <section className="testimonialsSection">
      {/* client videos */}
        {/* <h4>Watch Our Client Stories</h4>
      <div className="testimonialsSwiper">
     
        <Swiper
         spaceBetween={30}
         slidesPerView={1}
         pagination={false}
         navigation={false}
         autoplay={{ delay: 3000 }} 
         modules={[Pagination, Autoplay]}
         breakpoints={{
           768: {
             slidesPerView: 1,
           },
           769: {
             slidesPerView: 2, 
           },
           1040: {
             slidesPerView: 2, 
           },
         }}
        >
          {videos.map((video, index) => (
            <SwiperSlide key={index}>
              <div className="videoContainer">
                <iframe
                  width="100%"
                  height="315"
                  src={video.videoUrl}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div> */}

      {/* Swiper component for testimonials */}
    
     

      <h1 className='header'>What they say </h1>
    
      <div className="testimonialsSwiper">
      
        <Swiper
          spaceBetween={30}
          slidesPerView={1}
          pagination={false}
          navigation={false}
          autoplay={{ delay: 3000 }} 
          modules={[Pagination, Autoplay]}
          loop={true}          
          breakpoints={{
            768: {
              slidesPerView: 1,
            },
            769: {
              slidesPerView: 2, 
            },
            1040: {
              slidesPerView: 2, 
            },
          }}
        >
          {testimonials.map((testimonial, index) => (
            <SwiperSlide key={index}>
              <div className="testimonialCard">
                <img
                  src={testimonial.imageUrl}
                  alt={testimonial.name}
                  className="testimonialImage"
                />
                <div className="testimonialContent">
                  <p>"{testimonial.text}"</p>
                  <h4>{testimonial.name}</h4>
                  <span>{testimonial.position}</span>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
   
    </section>
  );
};

export default TestimonialsSection;
