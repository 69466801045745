import React, { useEffect, useState } from 'react';
import './css/ManagementContactPage.css'; 
import { fetchContactForms, deleteContactForm } from '../../services/api'; 
import Loader from '../../components/Loader'; 
import Swal from 'sweetalert2'; 
import { FaTrashAlt } from 'react-icons/fa';

const ContactPage = () => {
  const [contactForms, setContactForms] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const loadContactForms = async () => {
      try {
        const data = await fetchContactForms();
        setContactForms(data);
      } catch (error) {
        setErrorMessage('Error fetching contact form details.');
        console.log('Error fetching contact form details:', error);
      } finally {
        setLoading(false);
      }
    };

    loadContactForms();
  }, []);

  const handleDelete = async (id) => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    });

    if (result.isConfirmed) {
      setLoading(true);
      try {
        await deleteContactForm(id);
        setContactForms(contactForms.filter((form) => form._id !== id));

        Swal.fire('Deleted!', 'The contact form has been deleted.', 'success');
      } catch (error) {
        console.log('Error deleting contact form:', error);
        Swal.fire('Error!', 'There was an error deleting the contact form.', 'error');
      } finally {
        setLoading(false);
      }
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="contact-page">
      <h1 className="contact-title">Contact Form Submissions</h1>
      {errorMessage && <p className="error-message">{errorMessage}</p>}
      
      <div className="contact-table-container">
        <table className="courses-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Message</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {contactForms.map((form) => (
              <tr key={form._id}>
                <td data-label="Name">{form.name}</td>
                <td data-label="Email">{form.email}</td>
                <td data-label="Phone">{form.phone}</td>
                <td data-label="Message">{form.message}</td>
                <td data-label="Actions">
                  <button  onClick={() => handleDelete(form._id)} className="delete-btn">
                    <FaTrashAlt />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
  
};

export default ContactPage;
