import React, { useState, useEffect } from "react";
import "./css/BlogsPage.css";
import { fetchBlog } from "../services/api";
import { IoIosMore } from "react-icons/io";
import BlogsSection from "../components/BlogsSection";
import Loader from "../components/Loader";
import { useNavigate } from "react-router-dom";

const BlogsPage = () => {
  const [hoveredCard, setHoveredCard] = useState(null);
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  // Fetch blogs on component mount
  useEffect(() => {
    const fetchBlogs = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await fetchBlog();
        setBlogs(response); // Set fetched blogs
      } catch (error) {
        console.error("Error fetching blogs:", error);
        setError("Failed to load blogs. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchBlogs(); // Invoke blog fetch function
  }, []);

  const handleCardClick = (index) => {
    const blog = blogs[index];
    let title = blog?.title?.split(" ")?.join("");
    console.log(title, "title");

    navigate(`/blogs/blog=${title}`, { state: { id: blog._id } }); // Navigate to the blog detail page
  };

  const handleHover = (index) => {
    setHoveredCard(index);
  };

  const handleLeave = () => {
    setHoveredCard(null);
  };

  return (
    <div className="blogs-page main">
      <header className="banner">
        <h1 className="header">Blogs</h1>
      </header>

      <BlogsSection />

      {/* Loader and Error handling */}
      {loading ? (
        <Loader />
      ) : error ? (
        <p className="error-message">{error}</p> // Display error message if something goes wrong
      ) : blogs.length > 0 ? (
        <div className="blogs-container">
          {blogs.map((blog, index) => (
            <div
              className="blog-card"
              key={index}
              onClick={() => handleCardClick(index)} // Navigate to Blog Detail on click
              onMouseEnter={() => handleHover(index)}
              onMouseLeave={handleLeave}
            >
              <div className="blog-image">
                {blog?.image === null ? (
                  <img src="../assets/blog.gif" alt={blog.title} />
                ) : (
                  <img src={blog.image} alt={blog.title} />
                )}
              </div>
              <div className="blog-info">
                <div className="blog-icon">{blog.icon}</div>
                <h3>{blog.title}</h3>
                <p>
                  {blog.description} <IoIosMore />
                </p>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p className="info">No Tech Blogs Available</p>
      )}
    </div>
  );
};

export default BlogsPage;
