import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Swal from 'sweetalert2';
import './css/JobManagementPage.css'; // Import your CSS
import { fetchJobs, fetchApplicantsForJob, postJob, updateJob, deleteJob } from '../../services/api'; // Adjust the import path as needed

import ApplicantsList from './components/ApplicantsList';
import CourseList from './components/CoursesList';

const JobManagementPage = ({ userRole, access }) => {
  const [jobs, setJobs] = useState([]);
  const [applicants, setApplicants] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);
  const [formValues, setFormValues] = useState({ title: '', experience: '', location: '' });
  const [description, setDescription] = useState(''); // For ReactQuill value

  const edit = userRole === 'admin' || userRole === 'editor' || access?.Jobs?.edit;

  useEffect(() => {
    const loadJobs = async () => {
      try {
        const data = await fetchJobs();
        setJobs(data);
      } catch (error) {
        console.error('Error fetching jobs:', error);
      }
    };

    loadJobs();
  }, []);

  const handlePostOrUpdateJob = async (e) => {
    e.preventDefault();
    const { title, experience, location } = formValues;
    
    try {
      if (selectedJob) {
        // Update Job
        await updateJob(selectedJob._id, {
          title,
          experience,
          location,
          description, // Get the value from ReactQuill state
        });
        Swal.fire('Success', 'Job updated successfully!', 'success');
      } else {
        // Post New Job
        await postJob({
          title,
          experience,
          location,
          description, // Get the value from ReactQuill state
        });
        Swal.fire('Success', 'Job posted successfully!', 'success');
      }
      // Fetch the updated job list
      const data = await fetchJobs();
      setJobs(data);
      // Reset the form and clear selected job after submission
      resetForm();
    } catch (error) {
      console.error('Error posting/updating job:', error);
    }
  };

  const handleDeleteJob = async (jobId) => {
    try {
      await deleteJob(jobId);
      Swal.fire('Success', 'Job deleted successfully!', 'success');
      const data = await fetchJobs();
      setJobs(data);
    } catch (error) {
      console.error('Error deleting job:', error);
    }
  };

  const handleUpdateJob = (job) => {
    setFormValues({
      title: job.title,
      experience: job.experience,
      location: job.location,
    });
    setDescription(job.description); // Set the ReactQuill value for editing
    setSelectedJob(job);
  };

  const handleJobClick = async (job) => {
    setSelectedJob(job);
    try {
      const data = await fetchApplicantsForJob(job._id);
      setApplicants(data);
    } catch (error) {
      console.error('Error fetching applicants:', error);
    }
  };

  const resetForm = () => {
    setFormValues({ title: '', experience: '', location: '' });
    setDescription('');
    setSelectedJob(null);
  };

  return (
    <div className="job-management-page">
      <h3>Jobs</h3>
      {edit && (
        <form onSubmit={handlePostOrUpdateJob} className="post-job-form">
          <h2>{selectedJob ? 'Update Job' : 'Post a New Job'}</h2>
          <div>
            <label>Title:</label>
            <input
              type="text"
              name="title"
              value={formValues.title}
              onChange={(e) => setFormValues({ ...formValues, title: e.target.value })}
              required
            />
          </div>
          <div>
            <label>Experience:</label>
            <input
              type="text"
              name="experience"
              value={formValues.experience}
              onChange={(e) => setFormValues({ ...formValues, experience: e.target.value })}
              required
            />
          </div>
          <div>
            <label>Location:</label>
            <input
              type="text"
              name="location"
              value={formValues.location}
              onChange={(e) => setFormValues({ ...formValues, location: e.target.value })}
              required
            />
          </div>
          <div>
            <label>Description</label>
            <ReactQuill
              value={description} // Set the value of ReactQuill
              onChange={setDescription} // Update the state when Quill changes
              placeholder="Write your job description here..."
              modules={JobManagementPage.modules}
              formats={JobManagementPage.formats}
              required
            />
          </div>
          <button type="submit">{selectedJob ? 'Update Job' : 'Post Job'}</button>
        </form>
      )}

      {/* Job and Applicants Sections */}
      <div className="jobs-applicants-container">
        <CourseList
          edit={edit}
          courses={jobs}
          handleCourseClick={handleJobClick}
          handleUpdateCourse={handleUpdateJob}
          handleDeleteCourse={handleDeleteJob}
        />
        <ApplicantsList
          applicants={applicants}
          selectedCourse={selectedJob}
          resume={true}
        />
      </div>
    </div>
  );
};

// Rich text editor configurations
JobManagementPage.modules = {
  toolbar: [
    [{ header: '1' }, { header: '2' }, { font: [] }],
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    ['link'],
    ['clean'],
  ],
};

JobManagementPage.formats = [
  'header', 'font', 'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 
];

export default JobManagementPage;
