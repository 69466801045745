import React from "react";

import "./css/ServicesPage.css";
import FooterCertificates from "../../components/FooterCertificates";
import ImageSlider from "../../components/ImageSlider";

import ServicesSwiper from "../../components/ServicesSwiper";
import Promo from "../../components/Promo";
import DetailsSection from "../../components/ServiceDetails";

const DevOpsServicePage = () => {
  const service = {
    name: "DevOps",
    description:
      "DevOps accelerates your software delivery process by enabling collaboration, automation, and continuous integration.",
    images: ["../assets/devops/do3.jpeg", "../assets/devops/do1.jpg"],
    explanation: [
      // DEVOPS :-

      "Implement Agile methodologies to promote iterative development and enhance team collaboration.",

      "Provide 24/7 support for your DevOps infrastructure to ensure maximum uptime and quick issue resolution.",
      "Leverage continuous integration and continuous delivery (CI/CD) practices for rapid and reliable software releases.",
      "Utilize Infrastructure as Code (IaC) to automate provisioning and manage infrastructure consistently.",
      "Adopt containerization with Docker and Kubernetes to simplify application deployment and scaling.",
      "Monitor application performance and infrastructure health in real-time with proactive alerting.",
      "Foster cross-functional teams to enhance communication and collaboration between development and operations.",
    ],
    quotes: [
      "Automate, integrate, and optimize with our DevOps solutions.",
      // 'Unleashing the power of collaboration and automation.',
      "Continuous delivery for an ever-evolving tech landscape.",
    ],
    certificatesData: [
      {
        src: "../assets/certificates/google-cloud-devops.png",
        alt: "google cloud devops Certified ",
      },
      {
        src: "../assets/certificates/AWS-Certified-DevOps-Engineer-Professional.png",
        alt: "AWS Certified DevOps Engineer",
      },
      {
        src: "../assets/certificates/azure-devops-engineer.png",
        alt: "azure devops",
      },
    ],
    swipperimages: [
      { src: "../assets/devopsSolutions/ansible.jpg", caption: "Ansible" },
      { src: "../assets/devopsSolutions/docker.avif", caption: "Docker" },
      { src: "../assets/devopsSolutions/dynatrace.webp", caption: "Dynatrace" },
      { src: "../assets/devopsSolutions/eks.avif", caption: "Elastic Stack" },
      { src: "../assets/devopsSolutions/gitlab.png", caption: "Gitlab CI/CD" },
      { src: "../assets/devopsSolutions/grafana.webp", caption: "Grafana" },
      { src: "../assets/devopsSolutions/jenkins.png", caption: "Jenkins" },
      {
        src: "../assets/devopsSolutions/Kubernetes.webp",
        caption: "Kubernetes",
      },
      {
        src: "../assets/devopsSolutions/prometheus.webp",
        caption: "Prometheus",
      },
      {
        src: "../assets/devopsSolutions/servicenow.jpeg",
        caption: "Service Now",
      },
      { src: "../assets/devopsSolutions/splunk.png", caption: "Splunk" },
      { src: "../assets/devopsSolutions/terraform.jpg", caption: "Terraform" },
    ],
  };

  return (
    <section className="main">
      {/* Top Section with Background Image and Swiper */}
      <div className="serviceHeader">
        <ImageSlider images={service.images} quotes={service.quotes} />
      </div>

      {/* Details Section */}
      <DetailsSection
        name={service.name}
        images={service.images}
        explanation={service.explanation}
      />

      {/* New Section with Background Image and Button */}

      <ServicesSwiper swipperimages={service.swipperimages} />

      <Promo />

      {service.certificatesData.length > 0 && (
        <FooterCertificates certificates={service.certificatesData} />
      )}
    </section>
  );
};

export default DevOpsServicePage;
