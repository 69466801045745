import React from "react";

import "./css/ServicesPage.css";
import FooterCertificates from "../../components/FooterCertificates";
import ImageSlider from "../../components/ImageSlider";
import Promo from "../../components/Promo";
import DetailsSection from "../../components/ServiceDetails";

const SupportServicePage = () => {
  const service = {
    name: "Support & Maintenance",
    description:
      "Reliable 24x7 Application, Database, and System Support Services",
    images: [
      "../assets/SupportService/supportservice1.png",
      "../assets/SupportService/SupportService2.png",
    ],
    explanation: [
      "Our expert team provides 24x7 L1, L2, and L3 support services to minimize resolution times and ensure efficient issue resolution within strict SLA parameters.",
      "Proactive monitoring teams are dedicated to identifying and mitigating issues before they impact production environments, ensuring smooth operations.",
      "Emergency support is available for critical production system outages, along with ongoing performance monitoring and optimization.",
      "Our services include monthly system health checks, in-depth performance analytics, and statistics for growth analysis, allowing businesses to stay on top of system performance.",
      "Comprehensive system and OS administration services ensure optimal system configuration and security for maximum uptime.",
    ],
    quotes: ["", ""],
    certificatesData: [
      // {
      //   src: '../assets/certificates/Support-specialty.png',
      //   alt: 'Support Specialty Certification',
      // },
      // {
      //   src: '../assets/certificates/System-Admin-Certified.png',
      //   alt: 'Certified System Administrator',
      // },
      // {
      //   src: '../assets/certificates/Performance-Optimization-Certified.png',
      //   alt: 'Performance Optimization Specialist',
      // },
    ],
  };

  return (
    <section className="main">
      {/* Top Section with Background Image and Swiper */}
      <div className="serviceHeader">
        <ImageSlider images={service.images} quotes={service.quotes} />
      </div>

      {/* Details Section */}
      <DetailsSection
        name={service.name}
        images={service.images}
        explanation={service.explanation}
      />

      <Promo />

      {service.certificatesData.length > 0 && (
        <FooterCertificates certificates={service.certificatesData} />
      )}
    </section>
  );
};

export default SupportServicePage;
