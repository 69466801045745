import React from "react";

import "./css/ServicesPage.css";
import FooterCertificates from "../../components/FooterCertificates";
import ImageSlider from "../../components/ImageSlider";

import ServicesSwiper from "../../components/ServicesSwiper";
import Promo from "../../components/Promo";
import DetailsSection from "../../components/ServiceDetails";

const ProductManagementServicePage = () => {
  const service = {
    name: "Project Management",
    description:
      "Effective project management services that ensure successful project delivery.",
    images: [
      "../assets/management/pm2.webp",
      "../assets/management/pm1.webp",

      "../assets/management/pm3.webp",
      // '../assets/product-management/pm4.jpg',
      // '../assets/project-management/pm5.jpeg',
    ],
    explanation: [
      "Comprehensive product planning and strategy to align with market needs and business goals.",
      "User-centered design approach to ensure products meet customer expectations and enhance user experience.",
      "Agile methodologies for iterative development, allowing for flexibility and quick adaptation to change.",
      "Cross-functional collaboration to ensure alignment between development, marketing, and sales teams.",
      "Data-driven decision-making using analytics and user feedback to optimize product features.",
      "Seamless execution with continuous monitoring to track progress and ensure timely delivery.",
      "Stakeholder engagement to maintain transparency and gather insights throughout the product lifecycle.",
      "Post-launch evaluation and iterative improvements for ongoing product success and market relevance.",
    ],
    quotes: ["", "", ""],
    certificatesData: [
      {
        src: "../assets/certificates/pmp.png",
        alt: "PMP Certification",
      },
      {
        src: "../assets/certificates/cspo.jpeg",
        alt: "CSPO certified",
      },
      {
        src: "../assets/certificates/CDPM.webp",
        alt: "CDPMCertification",
      },
      {
        src: "../assets/certificates/pmc.jpeg",
        alt: "pmc Certification",
      },
    ],
    swipperimages: [
      {
        src: "../assets/managementSolutions/analytics.png",
        caption: "Product Analytic Management",
      },
      {
        src: "../assets/managementSolutions/collaboration.avif",
        caption: "Product Collaboration Management",
      },
      {
        src: "../assets/managementSolutions/execution.jpg",
        caption: "Product Execution Management",
      },
      {
        src: "../assets/managementSolutions/growth.png",
        caption: "Product Growth Management",
      },
      {
        src: "../assets/managementSolutions/innovation.jpg",
        caption: "Product Innovation Management",
      },
      {
        src: "../assets/managementSolutions/lifecycle.avif",
        caption: "Product Lifecycle Management",
      },
      {
        src: "../assets/managementSolutions/priortization.webp",
        caption: "Product Priortization Management",
      },
      {
        src: "../assets/managementSolutions/roadmap.webp",
        caption: "Product Roadmap",
      },

      {
        src: "../assets/managementSolutions/strategy.jpg",
        caption: "Product Strategy",
      },
    ],
  };

  return (
    <section className="main">
      {/* Top Section with Background Image and Swiper */}
      <div className="serviceHeader">
        <ImageSlider images={service.images} quotes={service.quotes} />
      </div>

      {/* Details Section */}
      <DetailsSection
        name={service.name}
        images={service.images}
        explanation={service.explanation}
      />

      {/* New Section with Background Image and Button */}
      <ServicesSwiper swipperimages={service.swipperimages} />

      <Promo />

      {service.certificatesData.length > 0 && (
        <FooterCertificates certificates={service.certificatesData} />
      )}
    </section>
  );
};

export default ProductManagementServicePage;
