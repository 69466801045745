import './css/Promo.css'
import { Link } from 'react-router-dom';
const Promo =()=>{
return(
  <section className="promoSection">
  <div className="promoContent">
    <h2 className='sub-header'>We Provide The Best Service In Industry</h2>
    <Link to="/contact-us" className="promoButton">
    <button>Contact Us Now</button></Link>
  </div>
</section>
)
}

export default Promo;