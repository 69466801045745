import React from 'react';
import PropTypes from 'prop-types';
import './css/FooterCertificates.css'; 

const FooterCertificates = ({ certificates }) => {

  return (
    <>
      <h1 className='header'>Our Certifications</h1>
    <div className="footerTop">
    
      {certificates.map((certificate, index) => (
        <img
        data-aos="fade-up"
          key={index}
          src={certificate.src}
          alt={certificate.alt}
          className="footerImage"
        />
      ))}
    </div>
    </>
  );
};

// PropTypes to ensure the component receives an array of objects
FooterCertificates.propTypes = {
  certificates: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default FooterCertificates;
