import React from 'react';
import { FaEye, FaEdit, FaTrashAlt } from 'react-icons/fa';

const CoursesList = ({ edit, courses, handleCourseClick, handleUpdateCourse, handleDeleteCourse }) => {
  return (
    <div className="jobs-section">
      <h2>Posts</h2>
      <table className="courses-table">
        <thead>
          <tr>
            <th>Title</th>
            <th>Duration</th>
            <th>Location</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {courses.map((course) => (
            <tr key={course._id}>
              <td data-label="Title">{course.CourseName || course.title}</td>
              <td data-label="Duration">{course.duration || course.experience}</td>
              <td data-label="Location">{course.location}</td>
              <td data-label="Actions">
                <button onClick={() => handleCourseClick(course)}><FaEye/></button>
                {edit && (
                  <>
                    <button onClick={() => handleUpdateCourse(course)}><FaEdit/></button>
                    <button onClick={() => handleDeleteCourse(course._id)}><FaTrashAlt/></button>
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CoursesList;
