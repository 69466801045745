import React, { useState, useEffect } from 'react';
import './css/Navbar.css'; 
import { FaBars, FaTimes, FaMoon, FaSun } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';

const MyNavbar = () => {

  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');
  
  useEffect(() => {
    document.documentElement.setAttribute('data-theme', theme);
    localStorage.setItem('theme', theme);
  }, [theme]);

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === 'light' ? 'dark' : 'light'));
  };

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdown2Open, setIsDropdown2Open] = useState(false);
  const [isCloudDropdownOpen, setIsCloudDropdownOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleMouseEnter = () => setIsDropdownOpen(true);
  const handleMouseLeave = () => setIsDropdownOpen(false);
  const handleMouse2Enter = () => setIsDropdown2Open(true);
  const handleMouse2Leave = () => setIsDropdown2Open(false);
  const handleCloudMouseEnter = () => setIsCloudDropdownOpen(true);
  const handleCloudMouseLeave = () => setIsCloudDropdownOpen(false);

  const handleMobileMenuToggle = () => setIsMobileMenuOpen(!isMobileMenuOpen);

  // Function to close all menus
  const closeAllMenus = () => {
    setIsDropdownOpen(false);
    setIsDropdown2Open(false);
    setIsCloudDropdownOpen(false);
    setIsMobileMenuOpen(false);
  };

  return (
    <nav className={`navbar ${isMobileMenuOpen ? 'open' : ''}`}>
      <div className="navbar-left">
        {/* Logo Section */}
        <a href="/" className="navbar-logo">
          <img src="../assets/logo.jpg" alt="Logo" />
        </a>
      </div>

      <button className="mobile-menu-toggle" onClick={handleMobileMenuToggle}>
        {isMobileMenuOpen ? <FaTimes/> : <FaBars/>}
      </button>

      <div className={`navbar-right ${isMobileMenuOpen ? 'open' : ''}`}>
        <div>
          <input 
            type="checkbox" 
            className="checkbox" 
            id="checkbox" 
            checked={theme === 'dark'} // Assuming 'dark' theme is checked
            onChange={toggleTheme} // Call toggleTheme on change
          />
          <label htmlFor="checkbox" className="checkbox-label">
            <FaMoon />
            <FaSun />
            <span className="ball"></span>
          </label>
        </div>
        
        <NavLink to="/about" className="navbar-link" activeclassname="active-link" onClick={closeAllMenus}>
          About Us
        </NavLink>

        {/* Dropdown for Services */}
        <div
          className="navbar-link dropdown"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          Services
          {isDropdownOpen && (
            <div className="dropdown-content">
              <div
                className="navbar-link dropdown cloud-dropdown"
                onMouseEnter={handleCloudMouseEnter}
                onMouseLeave={handleCloudMouseLeave}
              >
                Cloud Solutions
                {isCloudDropdownOpen && (
                  <div className="cloud-dropdown-content">
                    <NavLink to="/services/googleCloud" className="dropdown-item" activeclassname="active-link" onClick={closeAllMenus}>
                      Google Cloud
                    </NavLink>
                    <NavLink to="/services/aws" className="dropdown-item" activeclassname="active-link" onClick={closeAllMenus}>
                      AWS Solutions
                    </NavLink>
                    <NavLink to="/services/azure" className="dropdown-item" activeclassname="active-link" onClick={closeAllMenus}>
                      Azure
                    </NavLink>
                    <NavLink to="/services/salesforce" className="dropdown-item" activeclassname="active-link" onClick={closeAllMenus}>
                      Salesforce
                    </NavLink>
                  </div>
                )}
              </div>
              <NavLink to="/services/devops" className="dropdown-item" activeclassname="active-link" onClick={closeAllMenus}>
                DevOps
              </NavLink>
              <NavLink to="/services/webAppDev" className="dropdown-item" activeclassname="active-link" onClick={closeAllMenus}>
                Web & App Development
              </NavLink>
              <NavLink to="/services/networking" className="dropdown-item" activeclassname="active-link" onClick={closeAllMenus}>
                IT Infra & Networking
              </NavLink>
              <NavLink to="/services/testing" className="dropdown-item" activeclassname="active-link" onClick={closeAllMenus}>
                Automation & Manual Testing
              </NavLink>
              <NavLink to="/services/productManagement" className="dropdown-item" activeclassname="active-link" onClick={closeAllMenus}>
                Product Management
              </NavLink>
              <NavLink to="/services/DigitalMarketing" className="dropdown-item" activeclassname="active-link" onClick={closeAllMenus}>
                Digital Marketing
              </NavLink>
              <NavLink to="/services/SupportService" className="dropdown-item" activeclassname="active-link" onClick={closeAllMenus}>
                Support & Maintenance
              </NavLink>
              <NavLink to="/services/trainings" className="dropdown-item" activeclassname="active-link" onClick={closeAllMenus}>
                Training
              </NavLink>
            </div>
          )}
        </div>

        <div
          className="navbar-link dropdown"
          onMouseEnter={handleMouse2Enter}
          onMouseLeave={handleMouse2Leave}
        >
          Our Company
          {isDropdown2Open && (
            <div className="dropdown-content">
              <NavLink to="/customer-stories" className="dropdown-item" activeclassname="active-link" onClick={closeAllMenus}>
                Customer Stories
              </NavLink>
              <NavLink to="/careers" className="dropdown-item" activeclassname="active-link" onClick={closeAllMenus}>
                Careers
              </NavLink>
              <NavLink to="/life-at-vijprat" className="dropdown-item" activeclassname="active-link" onClick={closeAllMenus}>
                Life At Vijprat
              </NavLink>
            </div>
          )}
        </div>

        <NavLink to="/blogs" className="navbar-link" activeclassname="active-link" onClick={closeAllMenus}>
          Blogs
        </NavLink>

        <NavLink to="/contact-us" className="navbar-link" activeclassname="active-link" onClick={closeAllMenus}>
          Contact
        </NavLink>
      </div>
    </nav>
  );
};

export default MyNavbar;
