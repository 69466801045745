import React,{useState} from 'react';
import styles from './css/Footer.css';
import { FaPhoneAlt, FaEnvelope, FaFacebook, FaLinkedin, FaInstagram } from 'react-icons/fa';
import { FaXTwitter } from "react-icons/fa6"
import { IoLogoWhatsapp } from 'react-icons/io';
import { Subscribenewsletter } from '../services/api';

const companyLinks = [
  { label: 'About Us', href: '/about' },
  { label: 'Contact Us', href: '/contact-us' },
  { label: 'Life at Vijprat', href: '/life-at-vijprat' },
  { label: 'Careers', href: '/careers' },
  { label: 'Privacy Policy', href: '#' },

];

// Data for Solutions links
const solutionsLinks = [
  { label: 'DevOps Solution', href: '/services/devops' },
  { label: 'Web Development', href: '/services/webAppDev' },
  { label: 'Google Cloud Services', href: '/services/googleCloud' },
  { label: 'AWS Solutions', href: '/services/aws' },
  { label: 'Azure Solutions', href: '/services/azure' },
  { label: 'Salesforce', href: '/services/salesforce' },
 
  { label: 'Automation & manual Testing', href: '/services/testing' },
  { label: 'IT Infra & Networking', href: '/services/networking' },
  { label: 'Product Management', href: '/services/productManagement' },
  { label: 'Digital Marketing', href: '/services/DigitalMarketing' },
];

// Data for Resources links
const resourcesLinks = [
  { label: 'Blogs', href: '/blogs' },
  { label: 'Trainings and Courses', href: '/services/courses' },
  { label: 'Customer', href: '/customer-stories' },

];

// Data for contact information
const contactInfo = [
  {
    icon: <FaPhoneAlt className={styles.contactIcon} />,
    details: [
      { label: '+91 85650 13979', href: 'tel:+918565013979' },
      // { label: '+098 765 4321', href: 'tel:+0987654321' },
    ],
  },
  {
    icon: <FaEnvelope className={styles.contactIcon} />,
    details: [
      // { label: 'hrvijprat@gmail.com', href: 'mailto:hrvijprat@gmail.com' },
      { label: 'connect@vijprat.com', href: 'mailto:connect@vijprat.com' },
    ],
  },
  {
    icon: <IoLogoWhatsapp  className={styles.contactIcon} />,
    details: [
      { label: 'connect on whatsapp', href: 'https://wa.me/918565013979' },
      
    ],
  },
];

// Data for location boxes
const locations = [
  {
    address: 'Sangam Vihar, Hamdard Nagar, New Delhi, India',
    image: '../assets/locationIndia.jpg',
  },
  {
    address: '200 Kirwin Dr Front Worth, TX 76131 United States',
    image: '../assets/locationtexas.jpg',
  },
];

const Footer = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [error, setError] = useState(false);

  // Handle form submission for newsletter subscription
  const handleSubscription = async () => {
    if (!email) {
      setMessage('Please enter a valid email address.');
      setError(true);
      return;
    }

    setLoading(true);
    setError(false);
    setMessage('');

    try {
      const response = await Subscribenewsletter({email});

    console.log(response)
      setMessage(response.message);
      setEmail('');
    } catch (error) {
   
      console.log(error)
      setMessage(error.message || 'An error occurred. Please try again.');
      setError(true);
    } finally {
      setLoading(false); 
    }
  };

  return (
    <footer className="footer">
      {/* Footer Top Section */}
      <hr />

      {/* Footer Bottom Section */}
      <div className="footerBottom">
     
{/* Box 3: Solutions Links */}
<div className="footerBox">
          <h3>Solutions</h3>
          <ul className="footerLinks">
            {solutionsLinks.map((link, index) => (
              <li key={index}>
                <a href={link.href}>{link.label}</a>
              </li>
            ))}
          </ul>
        </div>
        {/* Box 2: Company Links */}
        <div className="footerBox">
          <h3>Company</h3>
          <ul className="footerLinks">
            {companyLinks.map((link, index) => (
              <li key={index}>
                <a href={link.href}>{link.label}</a>
              </li>
            ))}
          </ul>
        </div>

        

        {/* Box 4: Resources Links */}
        <div className="footerBox">
          <h3>Resources</h3>
          <ul className="footerLinks">
            {resourcesLinks.map((link, index) => (
              <li key={index}>
                <a href={link.href}>{link.label}</a>
              </li>
            ))}
          </ul>
          <div className="contactInform">
            {contactInfo.map((info, index) => (
              <div key={index}>
                {info.icon}
                <div>
                  {info.details.map((detail, i) => (
                    <a href={detail.href} key={i}>
                      {detail.label}
                      <br />
                    </a>
                  ))}
                </div>
                {index !== contactInfo.length - 1 && <hr />}
              </div>
            ))}
          </div>
        </div>

           {/* Box 1: Subscribe to Newsletter */}
           <div className="footerBox">
          <img src="../logo.jpg" alt="Vijprat Logo" className="logo" />
          <h4>Subscribe to our newsletter</h4>

          <input
            type="email"
            name="Email"
            value={email}
            required
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            className="emailInput"
          />
          <button onClick={handleSubscription} className="submit-btn" disabled={loading}>
            {loading ? 'Subscribing...' : 'Submit'}
          </button>

          {/* Display success or error message */}
          {message && (
            <p className={error ? 'errorMessage' : 'successMessage'}>
              {message}
            </p>
          )}

          <div>
            <img src="../assets/ISO-certified.png" alt="ISO Logo" className="isoLogo" />
          </div>
        </div>
      </div>

      {/* Location Section */}
      <div className="locationSection">
        {locations.map((location, index) => (
          <div key={index} className="locationBox">
            <div className="locationContent">
              <div className="locationImage">
                <img src={location.image} alt={`Location ${index + 1}`} />
              </div>
              <div className="locationText">
                <p>{location.address}</p>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Social Icons */}
      <div className="socialsIcon">
        <div>©All rights reserved</div>
        <a href="https://www.facebook.com/profile.php?id=61566515007689" target="_blank" rel="noopener noreferrer">
          <FaFacebook />
        </a>
        <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
          <FaXTwitter />
        </a>
        <a href="https://www.linkedin.com/company/vijprat-tech-private-limited/" target="_blank" rel="noopener noreferrer">
          <FaLinkedin />
        </a>
        <a href=" https://www.instagram.com/vijprattechprivate " target="_blank" rel="noopener noreferrer">
          <FaInstagram />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
